import {useState, useEffect} from 'react';
import {CustomModal} from '@app/components/widgets/CustomModal';
import {RejectWithReasonModalTP} from '../common-component/RejectWithReasonModalTP';
import {ApproveModalTP} from '../common-component/ApprovalModalTP';
import {Grid, TextField, Box} from '@mui/material';
import {useStyles} from './styles';
import CustomTextFieldRowTP from '../common-component/CustomTextFieldRowTP';
import CustomTitle from '../common-component/CustomTitle';
import {FooterButtonTP} from '../common-component/footerButtonTP';
import {useSelector, useDispatch} from 'react-redux';
import {
  chemistRequestsCreator,
  approveChemistRequestCreator,
  rejectChemistRequestCreator,
  doctorApprovalSelector,
  doctorApprovalActions,
} from '@app/screens/module-specific-utilities/pages/doctor-approval/redux';
import {MarkNotification} from '@app/components/right-sidebar/NotificationModalHelper';
import {CircularProgress} from '@material-ui/core';

const ChemistRemoveInfo = (props: any) => {
  const classes = useStyles();
  const loader = useSelector(doctorApprovalSelector.getChemistRemoveLoading());

  return (
    <Grid container style={{flexDirection: 'row'}}>
      {loader && (
        <Box
          sx={{display: 'flex', justifyContent: 'center', mt: 10}}
          className={classes.loader}>
          <CircularProgress />
        </Box>
      )}
      <CustomTextFieldRowTP
        righTlable={'Name'}
        rightValue={props.data.name}
        leftLable={'Mobile Number'}
        leftValue={props.data.mobile}
      />
      <CustomTextFieldRowTP
        righTlable={'Address'}
        rightValue={props.data.address}
        leftLable={'Pincode'}
        leftValue={props.data.pinCode}
      />
      <CustomTextFieldRowTP
        righTlable={'City'}
        rightValue={props.data.cityName}
        leftLable={'GST Number'}
        leftValue={props.data.gstNumber}
      />
      <CustomTextFieldRowTP
        righTlable={'Email'}
        rightValue={props.data.email}
        leftLable={'Drug License Number'}
        leftValue={props.data.drugLicense}
      />
      <CustomTextFieldRowTP
        righTlable={'Landmark'}
        rightValue={props.data.landmark}
        leftLable={'Territory'}
        leftValue={props.data.territoryName}
      />
      <Grid container style={{marginTop: 20}}>
        <CustomTitle title={'Reason for Removing Chemist'} variant={'h6'} />
      </Grid>
      <TextField
        defaultValue={props.data.reasonForRemovalName}
        value={props.data.reasonForRemovalName}
        InputProps={{
          readOnly: true,
          className: classes.root,
        }}
      />
    </Grid>
  );
};

export const ChemistRemoveRequest = (props: any) => {
  const dispatch = useDispatch();
  const chemistRemoveData = useSelector(
    doctorApprovalSelector.getChemistRequests(),
  );
  const [viewRejectModal, setViewRejectModal] = useState<boolean>(false);
  const [approvePopup, setApprovePopup] = useState<boolean>(false);
  const chemistRemovalCompleted = useSelector(
    doctorApprovalSelector.getChemistRemoveDone(),
  );

  useEffect(() => {
    if (chemistRemovalCompleted) {
      if (props?.notificationId) {
        MarkNotification(props?.notificationId);
      }
    }
  }, [chemistRemovalCompleted]);

  const closeDocPopup = () => {
    props.onClose();
  };
  const closeRejectModal = () => {
    setViewRejectModal(false);
    setApprovePopup(false);
  };

  const body = {
    partyId: chemistRemoveData.partyId,
    partyName: chemistRemoveData.name,
    reason: '',
    patchName: '',
  };

  const handleChemistRemovalApprove = () => {
    dispatch(approveChemistRequestCreator(body));
  };

  const handleChemistRemovalRejection = (reason: string) => {
    const updatedBody = {...body, reason: reason};
    dispatch(rejectChemistRequestCreator(updatedBody));
    MarkNotification(props.notificationId);
  };

  useEffect(() => {
    dispatch(chemistRequestsCreator({partyId: props.partyId}));
  }, []);

  useEffect(
    function closeModal() {
      if (chemistRemovalCompleted) {
        closeDocPopup();
        dispatch(doctorApprovalActions.resetChemistRemoveDone(''));
      }
    },
    [chemistRemovalCompleted],
  );

  return (
    <div>
      <CustomModal
        fullWidth={true}
        open={props.viewPopupChemist}
        onClose={closeDocPopup}
        header={
          <CustomTitle title={'Basic  Customer Information'} variant={'h6'} />
        }
        children={<ChemistRemoveInfo data={chemistRemoveData} />}
        footer={
          <FooterButtonTP
            firstLabel={'Approve'}
            secondLabel={'Reject with Reason'}
            firstButtonStyle={{textTransform: 'none'}}
            secondButtonStyle={{textTransform: 'none'}}
            firstOnClick={() => setApprovePopup(true)}
            secondOnClick={() => setViewRejectModal(true)}
          />
        }
      />
      {viewRejectModal && (
        <RejectWithReasonModalTP
          viewRejectModal={viewRejectModal}
          closeModal={() => {
            closeRejectModal();
          }}
          reasonText={handleChemistRemovalRejection}
        />
      )}
      {approvePopup && (
        <ApproveModalTP
          viewApproveModal={approvePopup}
          closeModal={() => {
            setApprovePopup(false);
          }}
          onApprove={handleChemistRemovalApprove}
        />
      )}
    </div>
  );
};
