import {useEffect, useState} from 'react';
import {Box, CircularProgress} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {
  masterDataCreator,
  doctorInfoCreator,
  approveCreator,
  rejectCreator,
  doctorApprovalActions,
} from '@app/screens/module-specific-utilities/pages/doctor-approval/redux';
import {doctorApprovalSelector} from '@app/screens/module-specific-utilities/pages/doctor-approval/redux/selector';
import {CustomModal} from '@app/components/widgets/CustomModal';
import {useStyles} from './styles';
import DoctorInfoForm from './DoctorInfoForm';
import {DoctorRequestHeader} from './DoctorRequestHeader';
import {RejectWithReasonModalTP} from '../common-component/RejectWithReasonModalTP';
import {ApproveModalTP} from '../common-component/ApprovalModalTP';
import DoctorDetailsForm from './DoctorDetailsForm';
import {appSelector} from '@app/store/selectors';
import {FooterButtonTP} from '../common-component/footerButtonTP';
import {MarkNotification} from '@app/components/right-sidebar/NotificationModalHelper';
import {approveRequestCreator} from '@app/screens/module-specific-utilities/pages/doctor-approval/redux/slice';

export const ViewDoctorRequest = (props: any) => {
  const loaderTP = useSelector(appSelector.getAppLoading());
  const viewDocReqestClasses = useStyles();
  const dispatch = useDispatch();
  const [obj, setObj] = useState<any>({});
  const [rejectPopup, setRejectPopup] = useState(false);
  const [approvePopup, setApprovePopup] = useState(false);

  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

  const getMasterData: any = useSelector(
    doctorApprovalSelector.getMasterData(),
  );
  const docInfo: any = useSelector(doctorApprovalSelector.getDoctorInfo());
  const docInfoError: any = useSelector(doctorApprovalSelector.getInfoError());
  const docApproveError: any = useSelector(
    doctorApprovalSelector.docApproveState(),
  );
  const docRejectError: any = useSelector(
    doctorApprovalSelector.docRejectState(),
  );
  const docRequestState: any = useSelector(
    doctorApprovalSelector.getDocRequestStateSelector(),
  );

  const [allPatches, setAllPatches] = useState<any>([]);
  const [areaWeekList, setAreaWeekList] = useState<any>([]);
  const [view, setView] = useState<boolean>(true);

  const patchesArray = Array.from(new Array(obj?.numberOfVisits).keys()).map(
    val => {
      let patchName: string | null = null;
      const count = val + 1;
      const weekString = 'Week' + count;
      const dayString = 'Day' + count;
      const patchAvailable: any = allPatches?.find((data: any) =>
        data?.usedOn?.some(
          (patch: any) =>
            patch?.week ===
              Number(
                obj[weekString]?.toString().substr(weekString?.length - 1),
              ) && patch?.weekDay === obj[dayString],
        ),
      );
      if (patchAvailable) {
        patchName = patchAvailable?.displayName;
      }
      return patchName;
    },
  );
  const patchPayload = patchesArray.join(' + ');

  const addDoctorFromUniverse =
    props?.doc?.actionType === 'Doctor From Universe';

  const doctorObj = useSelector(doctorApprovalSelector.getPreviewDoctor());
  let finalData = {...doctorObj};
  finalData.cityName = finalData?.city;
  finalData.qualificationName = finalData?.qualifications?.[0]?.name;
  finalData.whatsappNumber = finalData?.whatsappNo;
  finalData.specialisationName = finalData?.specialityQualifications?.[0]?.name;
  finalData.areaMappedtoDoctorName = finalData?.areas?.[0]?.name;
  finalData.practicingSince = finalData?.practiceSince;
  finalData.dateOfBirth = finalData?.birthday;
  finalData.dateOfMarriage = finalData?.anniversary;

  const closePreviewModal = () => {
    setShowPreviewModal(false);
  };

  useEffect(() => {
    const docPostData: any = {
      docId: props?.doc?.partyId,
      addedInMSL: false,
      addDoctorFromUniverse: addDoctorFromUniverse,
    };
    dispatch(doctorInfoCreator(docPostData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setObj({});
    if (docInfo?.id) {
      const id = docInfo?.mrstaffPositionId;
      const body: any = [
        {sectionName: 'areamr', param1: id, param2: 'string'},
        {sectionName: 'patchmr', param1: id, param2: 'string'},
        {sectionName: 'workingdaysmr', param1: id, param2: 'string'},
      ];
      dispatch(masterDataCreator(body));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docInfo]);

  useEffect(() => {
    if (docInfoError) {
      closeDocPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docInfoError]);

  const sortingByNameAndWeek = (array: any) => {
    if (array?.length > 0) {
      return array?.sort((a: any, b: any) => {
        const first = a?.name ? a?.name.split(', ') : a;
        const next = b?.name ? b?.name.split(', ') : b;
        return first[0]?.localeCompare(next[0]);
      });
    }
  };

  useEffect(() => {
    const areaId: any = docInfo?.areaId;
    const masterAreas: any = getMasterData?.filter((key: any) => {
      return key.sectionName === 'areamr';
    });
    const masterPatches: any = getMasterData?.filter((key: any) => {
      return key.sectionName === 'patchmr';
    });
    const patches: any =
      masterPatches?.length > 0 ? masterPatches[0]?.masterData : [];
    setAllPatches(patches);

    const areas: any =
      masterAreas?.length > 0 ? masterAreas[0]?.masterData : [];
    const findArea: any =
      areas.filter((area: any) => area?.id === areaId) || null;

    if (findArea && findArea.length > 0) {
      obj['area'] = findArea[0]?.name ? findArea[0]?.name : null;
      const weekDayList: any = [];
      const filterAreas = patches.filter((patch: any) =>
        patch?.areaId?.some((area: any) => area === findArea[0]?.id),
      );
      filterAreas?.map((area: any) => {
        return area?.usedOn?.map((obj: any) => {
          weekDayList.push({
            name: `Week ${obj?.week}, ${obj?.weekDay}`,
          });
        });
      });
      setAreaWeekList(sortingByNameAndWeek(weekDayList));
    }
    loadDocInfo();
  }, [getMasterData]);

  const loadDocInfo = () => {
    if (docInfo['visits']) {
      const splitVisits = docInfo['visits']?.split(',');
      splitVisits?.map((val: any, key: any) => {
        const [week, day] = [...val.split('-')];
        obj[`Week${key + 1}`] = `Week ${week}`;
        obj[`Day${key + 1}`] = day;
      });
    }

    let gender: any = docInfo['gender']?.trim();

    if (gender == 'M') {
      obj['gender'] = 'Male';
    } else if (gender == 'F') {
      obj['gender'] = 'Female';
    }

    obj['isKycCore'] = docInfo['isKycCore'] ? 'Yes' : 'No';
    const newData = {...docInfo, ...obj};
    setObj(newData);
  };

  const closeDocPopup = () => {
    props.onClose();
    setObj({});
    dispatch(approveRequestCreator({universetype: 'doctor'}));
  };

  const onApprove = () => {
    const postData: any = {
      partyId: docInfo?.id,
      partyName: docInfo?.name,
      Reason: 'Approve',
      patchName: patchPayload,
      addDoctorFromUniverse: addDoctorFromUniverse,
    };
    dispatch(approveCreator(postData));
    setApprovePopup(false);
  };

  useEffect(() => {
    if (docApproveError) {
      dispatch(doctorApprovalActions.setApprove(false));
      closeDocPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docApproveError]);

  useEffect(() => {
    if (docRejectError) {
      dispatch(doctorApprovalActions.setReject(false));
      closeRejectDocPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docRejectError]);

  useEffect(() => {
    if (docRequestState && docInfo?.id) {
      MarkNotification(props?.notificationId);
      closeRejectDocPopup();
      closeDocPopup();
      dispatch(doctorApprovalActions.setDocRequestState(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docRequestState]);

  const onReject = async (reason: any) => {
    const postData: any = {
      partyId: docInfo?.id,
      partyName: docInfo?.name,
      Reason: reason,
      addDoctorFromUniverse: addDoctorFromUniverse,
    };
    dispatch(rejectCreator(postData));
    MarkNotification(props.notificationId);
  };

  const closeRejectDocPopup = () => {
    setRejectPopup(false);
    setApprovePopup(false);
    props.onClose();
    setObj({});
  };

  const handleScreen = () => {
    setView(!view);
  };
  return (
    <div>
      <CustomModal
        fullWidth={true}
        open={props.viewPopup && !showPreviewModal}
        onClose={closeDocPopup}
        header={
          <DoctorRequestHeader
            doctorData={docInfo}
            firstFormView={view}
            arrowBack={handleScreen}
          />
        }
        footer={
          view ? (
            <FooterButtonTP
              firstLabel={'Close'}
              secondLabel={'Next'}
              firstButtonStyle={{textTransform: 'none'}}
              secondButtonStyle={{textTransform: 'none'}}
              firstOnClick={() => closeDocPopup()}
              secondOnClick={() => {
                setView(view => !view);
              }}
            />
          ) : (
            <FooterButtonTP
              firstLabel={'Approve'}
              secondLabel={'Reject with Reason'}
              firstButtonStyle={{textTransform: 'none'}}
              secondButtonStyle={{textTransform: 'none'}}
              firstOnClick={() => setApprovePopup(true)}
              secondOnClick={() => setRejectPopup(true)}
            />
          )
        }>
        {loaderTP && (
          <Box
            sx={{display: 'flex', justifyContent: 'center', mt: 3}}
            className={viewDocReqestClasses.loaderTP}>
            <CircularProgress />
          </Box>
        )}
        {view && <DoctorInfoForm doctorInfoData={obj} />}
        {!view && (
          <DoctorDetailsForm
            doctorDetailsData={obj}
            patchData={allPatches}
            areaWeekList={areaWeekList}
          />
        )}
      </CustomModal>
      <CustomModal
        fullWidth={true}
        open={showPreviewModal}
        onClose={closePreviewModal}
        header={
          <DoctorRequestHeader
            doctorData={finalData}
            firstFormView={true}
            arrowBack={() => {}}
          />
        }>
        <DoctorInfoForm doctorInfoData={finalData} />
      </CustomModal>

      {rejectPopup && (
        <RejectWithReasonModalTP
          viewRejectModal={rejectPopup}
          closeModal={() => {
            setRejectPopup(false);
          }}
          reasonText={onReject}
        />
      )}

      {approvePopup && (
        <ApproveModalTP
          viewApproveModal={approvePopup}
          closeModal={() => {
            setApprovePopup(false);
          }}
          onApprove={onApprove}
        />
      )}
    </div>
  );
};
