import React, {useCallback} from 'react';
import {CustomModal} from '@app/components/widgets/CustomModal';
import {Button, TextField, Typography, Grid} from '@mui/material';

interface InfoModalProps {
  title: string;
  showInfoModal: boolean;
  closeModal: () => void;
  infoText: string;
}

const InfoModal: React.FC<InfoModalProps> = ({
  title,
  showInfoModal,
  closeModal,
  infoText,
}) => {
  const closeInfoModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <div>
      <CustomModal
        maxWidth="xs"
        open={showInfoModal}
        onClose={closeInfoModal}
        header={
          <Typography variant="h6" style={styles.infoLabel}>
            {title}
          </Typography>
        }
        children={
          <TextField
            variant="outlined"
            multiline
            rows={10}
            value={infoText}
            InputProps={{readOnly: true}}
            style={styles.infoTextInput}
          />
        }
        footer={
          <Grid container justifyContent="center" style={styles.btnGroup}>
            <Button
              variant="outlined"
              size="medium"
              data-testId="info-goBack-button"
              style={{...styles.closeBtn, textTransform: 'none'}}
              onClick={closeModal}>
              Close
            </Button>
          </Grid>
        }
      />
    </div>
  );
};

export default InfoModal;

const styles = {
  infoLabel: {
    fontFamily: 'Poppins-Regular',
    fontSize: '22.7px',
    color: '#1b1938',
  },
  infoTextInput: {
    width: '100%',
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0',
  },
  closeBtn: {
    width: 200,
    height: '42.7px',
    borderRadius: '6.7px',
    borderWidth: '1.3px',
    borderColor: '#322b7c',
    fontFamily: 'Poppins',
    fontSize: '12.7px',
    textTransform: 'none',
  },
};
