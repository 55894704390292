import {CustomModal} from '@app/components/widgets/CustomModal';
import {FooterButtonTP} from './footerButtonTP';
import CustomTitle from './CustomTitle';

interface ApproveModalTPProps {
  viewApproveModal: boolean;
  closeModal: () => void;
  onApprove: () => void;
}

export const ApproveModalTP: React.FC<ApproveModalTPProps> = ({
  viewApproveModal,
  closeModal,
  onApprove,
}) => {
  return (
    <div>
      <CustomModal
        maxWidth={'xs'}
        style={{borderRadius: 13.3}}
        open={viewApproveModal}
        onClose={closeModal}
        header={
          <CustomTitle
            title={'Are you sure you want to approve?'}
            variant={'h5'}
          />
        }
        children={null}
        isCloseIconVisible={false}
        footer={
          <FooterButtonTP
            firstLabel={'Yes'}
            secondLabel={'No'}
            firstOnClick={() => {
              onApprove();
              closeModal();
            }}
            secondOnClick={closeModal}
            customStyle={{width: 180}}
            firstButtonStyle={{width: 70, textTransform: 'none'}}
            secondButtonStyle={{width: 70, textTransform: 'none'}}
          />
        }
      />
    </div>
  );
};
