import {API_PATH, HTTP_OK} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put, all, select} from '@redux-saga/core/effects';
import {filterSelector} from '@app/components/right-sidebar/redux';
import {jsonDownloader, convertJsonToCSV} from '../../../common';
import {
  masterDataType,
  doctorApprovalActions,
  doctorInfoCreatorType,
  chemistsCreatorType,
  productsCreatorType,
  approveCreatorType,
  rejectCreatorType,
  approveRequestType,
  searchDoctorType,
  detailReportType,
  getPatchesType,
  getAreasType,
  getWorkingDayType,
  downloadDetailReportType,
  searchApprovalRequestsCreatorType,
  doctorRequestsCreatorType,
  approveDoctorRequestCreatorType,
  rejectDoctorRequestCreatorType,
  chemistRequestsCreatorType,
  approveChemistRequestCreatorType,
  rejectChemistRequestCreatorType,
  doctorExistsCreatorType,
  previewDoctorCreatorType,
} from './slice';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';

export function* fetchWorkingDayWatcher() {
  yield takeLatest(getWorkingDayType, fetchWorkingDayWorker);
}

export function* fetchWorkingDayWorker(action: any): any {
  const {staffPositionId} = action.payload;
  const url = `${API_PATH.workingDay}/${staffPositionId}`;
  try {
    const response = yield call(NetworkService.get, url, {}, {});
    let data = response && response?.data ? response?.data : [];
    yield put(doctorApprovalActions.getAllWorkingDay(data));
  } catch (error) {}
}

export function* fetchAreasWatcher() {
  yield takeLatest(getAreasType, fetchAreasWorker);
}
export function* fetchAreasWorker(action: any): any {
  const {staffPositionId} = action.payload;
  const url = `${API_PATH.areas}/${staffPositionId}`;
  try {
    const response = yield call(NetworkService.get, url, {}, {});
    let data = response && response?.data ? response?.data : [];
    yield put(doctorApprovalActions.getAreas(data));
  } catch (error) {}
}

export function* fetchPatchesWatcher() {
  yield takeLatest(getPatchesType, fetchPatchesWorker);
}

export function* fetchPatchesWorker(action: any): any {
  const {staffPositionId} = action.payload;
  const url = `${API_PATH.patchs}/${staffPositionId}`;
  try {
    const response = yield call(NetworkService.get, url, {}, {});
    let data = response && response?.data ? response?.data : [];
    yield put(doctorApprovalActions.getPatches(data));
  } catch (error) {}
}

export function* getDetailReportWatcher() {
  yield takeLatest(detailReportType, getDetailReportWorker);
}

export function* getDetailReportWorker(action: any): any {
  const {payload} = action;
  const url = `${API_PATH.detailReport.detailReport}`;
  try {
    const response = yield call(NetworkService.post, url, payload);
    if (HTTP_OK !== response?.status) {
      ToasterService.showToaster(
        'Failed to Fetch Detail Report.',
        ToastType.ERROR,
      );
      return;
    }
    yield put(doctorApprovalActions.setDetailReport(response?.data));
  } catch (error) {}
}

export function* searchDoctorWatcher() {
  yield takeLatest(searchDoctorType, searchDoctorWorker);
}

export function* searchDoctorWorker(action: any): any {
  const {payload} = action;
  const url = `${API_PATH.doctorApproval.searchDoctor}`;
  try {
    const response = yield call(NetworkService.post, url, payload);
    let data = response && response?.data ? response?.data : [];
    data[0].id = 11;
    yield put(doctorApprovalActions.setSearchDoctor(data));
  } catch (error) {}
}

export function* getApproveRequestWatcher() {
  yield takeLatest(approveRequestType, getApproveRequestWorker);
}

export function* getApproveRequestWorker(action: any): any {
  const {universetype} = action.payload;
  const url = `${API_PATH.doctorApproval.approvalRequest}/${universetype}`;
  try {
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(doctorApprovalActions.setApproveRequests(response.data));
  } catch (error) {}
}

export function* fetchMasterDataWatcher() {
  yield takeLatest(masterDataType, fetchMasterDataWorker);
}

export function* fetchMasterDataWorker(action: any): any {
  const {staffPositionId, type} = action?.payload;
  try {
    const data = action?.payload;
    const url = `${API_PATH.doctorApproval.masterData}`;
    const response = yield call(NetworkService.post, url, data, {});
    if (HTTP_OK !== response?.status) {
      ToasterService.showToaster(
        'Failed to Fetch Master Data.',
        ToastType.ERROR,
      );
      return;
    }
    if (response?.status === HTTP_OK) {
      if (action.payload[0].sectionName === 'specialitybyqualification') {
        yield put(doctorApprovalActions.setQualification(response));
      } else if (action.payload[0].sectionName === 'chemistforsurvey') {
        yield put(doctorApprovalActions.setChemistSurvey(response));
      } else {
        yield put(
          doctorApprovalActions.setMasterData([...(response?.data || [])]),
        );
      }
    }
  } catch (error) {
    console.log('ERROR:', error);
  }
}

export function* fetchDoctorInfoWatcher() {
  yield takeLatest(doctorInfoCreatorType, fetchDoctorInfoWorker);
}
export function* fetchDoctorInfoWorker(action: any): any {
  const {docId, addedInMSL, addDoctorFromUniverse} = action.payload;
  yield put(doctorApprovalActions.setDocRequestState(false));
  yield put(doctorApprovalActions.setDocInfoError(false));

  let url = `${API_PATH.doctorApproval.getDocById}/${docId}`;
  if (addDoctorFromUniverse) {
    url = `${API_PATH.doctorApproval.getDocFromById}/${docId}`;
  }
  try {
    const response = yield call(NetworkService.get, url, {}, {});
    if (HTTP_OK !== response?.status) {
      yield put(doctorApprovalActions.setDocInfoError(true));
      yield put(doctorApprovalActions.setDoctorInfo([]));
      ToasterService.showToaster(
        'Failed to Fetch Doctor Info.',
        ToastType.ERROR,
      );
      return;
    }
    yield put(doctorApprovalActions.setDocInfoError(false));
    yield put(doctorApprovalActions.setDoctorInfo(response.data));
  } catch (error) {}
}

export function* fetchChemistsWatcher() {
  yield takeLatest(chemistsCreatorType, fetchChemistsWorker);
}

export function* fetchChemistsWorker(action: any): any {
  const {staffPositionId} = action.payload;
  const valueMap = {
    staffPositionId: staffPositionId,
  };
  const url = `${API_PATH.doctorApproval.fetchChemists}/${staffPositionId}/chemist`;
  try {
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(doctorApprovalActions.setChemists(response.data));
  } catch (error) {}
}

export function* fetchproductsWatcher() {
  yield takeLatest(productsCreatorType, fetchProductsWorker);
}

export function* fetchProductsWorker(action: any): any {
  const {staffPositionId} = action.payload;
  const valueMap = {
    staffPositionId: staffPositionId,
  };
  const url = `${API_PATH.doctorApproval.fetchProducts}/${staffPositionId}/submotherbrand`;
  try {
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(doctorApprovalActions.setProducts(response.data));
  } catch (error) {}
}

export function* approveDoctorWatcher() {
  yield takeLatest(approveCreatorType, approveDoctorWorker);
}

export function* approveDoctorWorker(action: any): any {
  yield put(doctorApprovalActions.setApprove(false));
  yield put(doctorApprovalActions.setDocRequestState(false));

  let url = `${API_PATH?.doctorApproval?.approve}`;
  let payload = action?.payload;

  const notificationUrl = `${API_PATH?.doctorApproval?.sendNotification}`;

  try {
    let response;
    response = yield call(NetworkService.post, url, payload);
    if (response?.status === HTTP_OK) {
      if (response?.data?.isSuccess) {
        ToasterService.showToaster(
          'Doctor Approve Request Processed.',
          ToastType.SUCCESS_ACTION,
        );

        payload = {
          ...payload,
          partyType: 5,
          smId: response?.data?.partyId,
          DoctorToUniverseApprovalAdminToMr: true,
          DoctorToUniverseApprovalAdminToManager: true,
        };
        const notificationResponse = yield call(
          NetworkService.post,
          notificationUrl,
          payload,
        );
        if (notificationResponse?.status !== HTTP_OK) {
          return ToasterService.showToaster(
            'Failed to Process Doctor Approve Request.',
            ToastType.ERROR,
          );
        }

        if (response?.status == HTTP_OK) {
          yield put(doctorApprovalActions.setDocRequestState(true));
        }
      } else {
        ToasterService.showToaster(
          'Unable to process your request due to WSFA Error Please try after sometime',
          ToastType.ERROR,
        );
        return;
      }
    }
    if (HTTP_OK !== response?.status) {
      yield put(doctorApprovalActions.setApprove(true));
      ToasterService.showToaster(
        'Failed to Process Doctor Approve Request.',
        ToastType.ERROR,
      );
      return;
    }
  } catch (error) {}
}

export function* rejectDoctorWatcher() {
  yield takeLatest(rejectCreatorType, rejectDoctorWorker);
}

export function* rejectDoctorWorker(action: any): any {
  yield put(doctorApprovalActions.setReject(false));
  let url = `${API_PATH.doctorApproval.reject}`;
  const notificationUrl = `${API_PATH.doctorApproval.sendNotification}`;
  let payload = action.payload;
  const isDoctorFrom = payload?.addDoctorFromUniverse;
  if (payload?.addDoctorFromUniverse) {
    url = `${API_PATH.doctorApproval.rejectFrom}`;
  }

  delete payload['patchName'];
  delete payload['addDoctorFromUniverse'];

  try {
    const response = yield call(NetworkService.post, url, payload);
    if (response?.status !== HTTP_OK) {
      yield put(doctorApprovalActions.setReject(true));
      ToasterService.showToaster(
        'Failed to Process Doctor Reject Request.',
        ToastType.ERROR,
      );
      return;
    }
    ToasterService.showToaster(
      'Doctor Reject Request Processed.',
      ToastType.SUCCESS_ACTION,
    );
    if (!isDoctorFrom) {
      const notificationPayload = {
        ...payload,
        DoctorToUniverseRejectionAdminToMr: true,
        DoctorToUniverseRejectionAdminToManager: true,
      };

      const notificationResponse = yield call(
        NetworkService.post,
        notificationUrl,
        notificationPayload,
      );

      if (notificationResponse?.status !== HTTP_OK) {
        ToasterService.showToaster(
          'Failed to Process Doctor Reject Request.',
          ToastType.ERROR,
        );
      }
      if (
        notificationResponse?.status === HTTP_OK ||
        response?.status === HTTP_OK
      ) {
        yield put(doctorApprovalActions.setDocRequestState(true));
      }
    }
  } catch (error) {}
}

export function* downloadDailyReportWatcher() {
  yield takeLatest(downloadDetailReportType, downloadDailyReportWorker);
}

export function* downloadDailyReportWorker(action: any): any {
  const {date} = action.payload;
  const filterState: any = yield select(filterSelector.getFilterState());
  const selectedDivisions = filterState?.Divisions;
  const selectedStates = filterState?.States;
  const selectedSpeciality = filterState?.Specialities;
  const divisionId: any = [],
    stateId: any = [],
    specialityId: any = [];

  Object.keys(selectedDivisions?.options).filter((key: any) => {
    if (selectedDivisions?.options[`${key}`]) {
      return selectedDivisions?.data.map((division: any) => {
        if (division.label == key) {
          divisionId.push(division?.value);
        }
      });
    }
  });
  Object.keys(selectedStates?.options).filter((key: any) => {
    if (selectedStates?.options[`${key}`]) {
      return selectedStates?.data.map((state: any) => {
        if (state.label == key) {
          stateId.push(state?.value);
        }
      });
    }
  });
  Object.keys(selectedSpeciality?.options).filter((key: any) => {
    if (selectedSpeciality?.options[`${key}`]) {
      return selectedSpeciality?.data.map((speciality: any) => {
        if (speciality.label == key) {
          specialityId.push(speciality?.value);
        }
      });
    }
  });

  const url = `${API_PATH.detailReport.getDetailingReport}`;
  // const payload = { date, divisionId: [21], stateId: [5515], specialityId: [45] };
  const payload = {date, divisionId, stateId, specialityId};
  try {
    const response = yield call(NetworkService.post, url, payload);
    if (HTTP_OK !== response?.status) {
      ToasterService.showToaster('Failed to Fetch Rports.', ToastType.ERROR);
      return;
    }
    if (response && response?.data) {
      if (response?.data.length <= 0) {
        ToasterService.showToaster('No Data Found.', ToastType.ERROR);
        return;
      }
      const data = convertJsonToCSV(response?.data);
      return jsonDownloader(data);
    }
  } catch (error) {}
}

export function* searchApprovalRequestsWatcher() {
  yield takeLatest(
    searchApprovalRequestsCreatorType,
    searchApprovalRequestsWorker,
  );
}

export function* searchApprovalRequestsWorker(action: any): any {
  let url = `${API_PATH.doctorApproval.searchMr}`;
  const payload = action.payload;
  yield put(doctorApprovalActions.setDocRequestState(false));

  try {
    if (parseInt(payload)) {
      url = `${API_PATH.doctorApproval.searchMr}?employeeCode=${payload}`;
    } else {
      url = `${API_PATH.doctorApproval.searchMr}?mrName=${payload}`;
    }
    const response = yield call(NetworkService.get, url, {}, {});

    yield put(doctorApprovalActions.setApproveRequests(response.data));
  } catch (error) {}
}

export function* doctorRequestsWatcher() {
  yield takeLatest(doctorRequestsCreatorType, doctorRequestsWorker);
}

export function* doctorRequestsWorker(action: any): any {
  yield put(doctorApprovalActions.setDoctorRequests(''));
  const payload = action.payload;
  const url = `${API_PATH.doctorApproval.getDoctorById}/${payload.partyId}?partyId=${payload.partyId}`;

  try {
    const response = yield call(NetworkService.get, url, {}, {});

    yield put(doctorApprovalActions.setDoctorRequests(response.data));
  } catch (error) {}
}

export function* approveDoctorRequestWatcher() {
  yield takeLatest(approveDoctorRequestCreatorType, approveDoctorRequestWorker);
}

export function* approveDoctorRequestWorker(action: any): any {
  const url = `${API_PATH.doctorApproval.approveDoctorRemoval}`;
  const notificationUrl = `${API_PATH.doctorApproval.sendNotification}`;
  const {payload} = action;

  try {
    let response;
    yield put(doctorApprovalActions.setDoctorRemoveLoading(true));
    response = yield call(NetworkService.post, url, payload);
    if (HTTP_OK === response?.status) {
      if (response?.data?.isSuccess) {
        ToasterService.showToaster(
          'Doctor Removal Request Processed.',
          ToastType.SUCCESS_ACTION,
        );

        const notificationPayload = {
          ...payload,
          DoctorRemovalApprovalAdminToMr: true,
        };
        const notificationResponse = yield call(
          NetworkService.post,
          notificationUrl,
          notificationPayload,
        );

        if (notificationResponse?.status !== HTTP_OK) {
          ToasterService.showToaster(
            'Failed to Process Doctor Removal Request.',
            ToastType.ERROR,
          );
        }
        yield put(doctorApprovalActions.setDoctorRemoveLoading(false));
        yield put(doctorApprovalActions.setDoctorRemoveDone(true));
      } else {
        ToasterService.showToaster(
          'Unable to process your request due to WSFA Error Please try after sometime',
          ToastType.ERROR,
        );
        yield put(doctorApprovalActions.setDoctorRemoveDone(false));
        yield put(doctorApprovalActions.setDoctorRemoveLoading(false));
      }
    }

    if (HTTP_OK !== response?.status) {
      yield put(doctorApprovalActions.setDoctorRemoveLoading(false));
      yield put(doctorApprovalActions.setDoctorRemoveDone(true));

      ToasterService.showToaster(
        'Failed to Process Doctor Removal Request.',
        ToastType.ERROR,
      );
    }
  } catch (error) {}
}

export function* rejectDoctorRequestWatcher() {
  yield takeLatest(rejectDoctorRequestCreatorType, rejectDoctorRequestWorker);
}

export function* rejectDoctorRequestWorker(action: any): any {
  const url = `${API_PATH.doctorApproval.rejectDoctorRemoval}`;
  const {payload} = action;
  const notificationUrl = `${API_PATH.doctorApproval.sendNotification}`;

  try {
    yield put(doctorApprovalActions.setDoctorRemoveLoading(true));
    const response = yield call(NetworkService.post, url, payload);

    if (HTTP_OK !== response?.status) {
      yield put(doctorApprovalActions.setDoctorRemoveLoading(false));
      yield put(doctorApprovalActions.setDoctorRemoveDone(true));

      ToasterService.showToaster(
        'Failed to Process Doctor Removal Request.',
        ToastType.ERROR,
      );
      return;
    }

    if (
      typeof response?.data === 'boolean' &&
      response?.data &&
      HTTP_OK === response?.status
    ) {
      ToasterService.showToaster(
        'Doctor Removal Request Processed.',
        ToastType.SUCCESS_ACTION,
      );

      yield put(doctorApprovalActions.setDoctorRemoveLoading(false));
      yield put(doctorApprovalActions.setDoctorRemoveDone(true));

      const notificationPayload = {
        ...payload,
        DoctorRemoveRejectedByAdminNotificationToMRAndManagers: true,
      };
      const notificationResponse = yield call(
        NetworkService.post,
        notificationUrl,
        notificationPayload,
      );

      if (notificationResponse?.status !== HTTP_OK) {
        ToasterService.showToaster(
          'Failed to Process Doctor Removal Request.',
          ToastType.ERROR,
        );
      }
    }
  } catch (error) {}
}

export function* chemistRequestsWatcher() {
  yield takeLatest(chemistRequestsCreatorType, chemistRequestsWorker);
}

export function* chemistRequestsWorker(action: any): any {
  const payload = action.payload;
  const url = `${API_PATH.doctorApproval.getChemistById}/${payload.partyId}?partyId=${payload.partyId}`;

  try {
    const response = yield call(NetworkService.get, url, {}, {});

    yield put(doctorApprovalActions.setChemistRequests(response.data));
  } catch (error) {}
}

export function* approveChemistRequestWatcher() {
  yield takeLatest(
    approveChemistRequestCreatorType,
    approveChemistRequestWorker,
  );
}

export function* approveChemistRequestWorker(action: any): any {
  const url = `${API_PATH.doctorApproval.approveChemistRemoval}`;
  const notificationUrl = `${API_PATH.doctorApproval.sendNotification}`;

  const {payload} = action;

  try {
    let response;

    yield put(doctorApprovalActions.setChemistRemoveLoading(true));

    response = yield call(NetworkService.post, url, payload);

    if (HTTP_OK === response?.status) {
      if (response?.data?.isSuccess) {
        ToasterService.showToaster(
          'Chemist Removal Request Processed.',
          ToastType.SUCCESS_ACTION,
        );

        yield put(doctorApprovalActions.setChemistRemoveLoading(false));
        yield put(doctorApprovalActions.setChemistRemoveDone(true));

        const notificationPayload = {
          ...payload,
          ChemistRemovalApprovalAdminToMr: true,
        };
        const notificationResponse = yield call(
          NetworkService.post,
          notificationUrl,
          notificationPayload,
        );

        if (notificationResponse?.status !== HTTP_OK) {
          ToasterService.showToaster(
            'Failed to Process Chemist Removal Request.',
            ToastType.ERROR,
          );
        }
      } else {
        yield put(doctorApprovalActions.setChemistRemoveLoading(false));
        yield put(doctorApprovalActions.setChemistRemoveDone(true));
        ToasterService.showToaster(
          'Unable to process your request due to WSFA Error Please try after sometime',
          ToastType.ERROR,
        );
        return;
      }
    }

    if (HTTP_OK !== response?.status) {
      yield put(doctorApprovalActions.setChemistRemoveLoading(false));
      yield put(doctorApprovalActions.setChemistRemoveDone(true));

      ToasterService.showToaster(
        'Failed to Process Chemist Removal Request.',
        ToastType.ERROR,
      );
      return;
    }
  } catch (error) {}
}

export function* rejectChemistRequestWatcher() {
  yield takeLatest(rejectChemistRequestCreatorType, rejectChemistRequestWorker);
}

export function* rejectChemistRequestWorker(action: any): any {
  const url = `${API_PATH.doctorApproval.rejectChemistRemoval}`;
  const {payload} = action;
  const notificationUrl = `${API_PATH.doctorApproval.sendNotification}`;

  try {
    yield put(doctorApprovalActions.setChemistRemoveLoading(true));

    const response = yield call(NetworkService.post, url, payload);

    if (HTTP_OK !== response?.status) {
      ToasterService.showToaster(
        'Failed to Process Chemist Removal Request.',
        ToastType.ERROR,
      );

      yield put(doctorApprovalActions.setChemistRemoveLoading(false));
      yield put(doctorApprovalActions.setChemistRemoveDone(true));

      return;
    }
    ToasterService.showToaster(
      'Chemist Removal Request Processed.',
      ToastType.SUCCESS_ACTION,
    );

    if (
      typeof response?.data === 'boolean' &&
      response?.data &&
      HTTP_OK === response?.status
    ) {
      ToasterService.showToaster(
        'Chemist Removal Request Processed.',
        ToastType.SUCCESS_ACTION,
      );

      yield put(doctorApprovalActions.setChemistRemoveLoading(false));
      yield put(doctorApprovalActions.setChemistRemoveDone(true));

      const notificationPayload = {
        ...payload,
        ChemistRemoveRejectionByAdminNotificationToMR: true,
      };
      const notificationResponse = yield call(
        NetworkService.post,
        notificationUrl,
        notificationPayload,
      );

      if (notificationResponse?.status !== HTTP_OK) {
        ToasterService.showToaster(
          'Failed to Process Chemist Removal Request.',
          ToastType.ERROR,
        );
      }
    }
  } catch (error) {}
}

export function* doctorExistsWatcher() {
  yield takeLatest(doctorExistsCreatorType, doctorExistsWorker);
}

export function* doctorExistsWorker(action: any): any {
  const url = `${API_PATH.doctorApproval.doctorExists}`;
  const {payload} = action;

  try {
    const response = yield call(NetworkService.post, url, payload);

    yield put(doctorApprovalActions.setDoctorExistsRequests(response.data));
  } catch (error) {}
}

export function* previewDoctorWatcher() {
  yield takeLatest(previewDoctorCreatorType, previewDoctorWorker);
}

export function* previewDoctorWorker(action: any): any {
  const {payload} = action;
  const url = `${API_PATH.doctorApproval.doctorInfo}/${
    payload?.staffPositionId
  }/doctordetail/${payload?.docId}/${false}`;

  try {
    const response = yield call(NetworkService.get, url, {}, {});

    yield put(doctorApprovalActions.setPreviewDoctor(response.data));
  } catch (error) {}
}
