
import React from 'react';
import PlanAndMeetFilter from './plan-and-meet';
import { navbarComponentName } from '@app/router/redux/routeHandler';

  interface RenderFiltersProps {
	filterComponent: JSX.Element;
	navbarComponentNameSelector: string; 
  }
  
  const RenderFilters: React.FC<RenderFiltersProps> = ({ filterComponent, navbarComponentNameSelector }) => {
	switch (navbarComponentNameSelector) {
	  case navbarComponentName.ComponentManagement:
	  case navbarComponentName.MasterDataManagement:
	  case navbarComponentName.ConfigurationManagement:
	  case navbarComponentName.approveExpenses:
	  case navbarComponentName.statusManager:
	  case navbarComponentName.Expense:
	  case navbarComponentName.commonheader:
		return filterComponent;
	  case navbarComponentName.planAndMeet:
	  case navbarComponentName.dcrUnlockRequest:
		return <PlanAndMeetFilter />;
	  case navbarComponentName.approvalWorkflow:
	  case navbarComponentName.positionManagement:
	  case navbarComponentName.activityMapping:
	  case navbarComponentName.home:
		return <></>;
	  default:
		return null;
	}
  };
  
  export default RenderFilters;