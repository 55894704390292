import React, {useState} from 'react';
import {Grid, TextField, Typography} from '@mui/material';
import {useStyles} from './styles';
import InfoModal from './InfoModal';

type Props = {
  label?: string;
  value?: string | number;
  customStyle?: React.CSSProperties;
  textLength?: number;
};

export const CustomTextFieldTP = ({
  label,
  value,
  customStyle,
  textLength = 30,
}: Props) => {
  const classes = useStyles();
  const [showMoreTextModal, setShowMoreTextModal] = useState(false);

  // Convert value to string, handle null and undefined cases
  const stringValue = value != null ? value.toString() : '';

  // Logic to display first textLength characters followed by "..." if necessary
  const displayValue =
    stringValue.length > textLength
      ? `${stringValue.substring(0, textLength)}...`
      : stringValue;

  // Function to handle the "More" click event
  const handleMoreClick = () => {
    setShowMoreTextModal(true);
  };

  return (
    <div
      style={{
        paddingTop: 'env(safe-area-inset-top)',
        paddingBottom: 'env(safe-area-inset-bottom)',
        paddingLeft: 'env(safe-area-inset-left)',
        paddingRight: 'env(safe-area-inset-right)',
      }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        className={classes.textFieldcontainer}
        style={customStyle}>
        {label && (
          <Grid item>
            <Typography className={classes.textFieldLabel}>{label}</Typography>
          </Grid>
        )}
        <Grid item>
          <TextField
            value={displayValue}
            InputProps={{
              readOnly: true,
              className: classes.root,
              endAdornment: stringValue.length > textLength && (
                <div style={{paddingRight: 18}}>
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{cursor: 'pointer'}}
                    onClick={handleMoreClick}>
                    More
                  </Typography>
                </div>
              ),
            }}
          />
        </Grid>
      </Grid>
      {showMoreTextModal && (
        <InfoModal
          title={label ?? ''}
          showInfoModal={showMoreTextModal}
          closeModal={() => setShowMoreTextModal(false)}
          infoText={stringValue}
        />
      )}
    </div>
  );
};

export default CustomTextFieldTP;
