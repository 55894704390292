import {useCallback, useEffect, useState} from 'react';
import './modal.css';
import IconButton from '@mui/material/IconButton';
import Modal from 'react-modal';
import {NotificationsNoneOutlined, Sync} from '@mui/icons-material';

import env from '@app/env.json';
import {useDispatch, useSelector} from 'react-redux';
import {getAccessToken} from '@app/helper/login';
import moment from 'moment';
import {Button, List} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {routeSelector} from '@app/router/redux';
import {NOTIFICATION_REDIRECT} from '@app/common/constants';
import {filterSelector, filterStateActions} from './redux';
import {DoctorRemoveRequest} from '@app/screens/module-specific-utilities/components/doctor-approval/view-doctor-remove-request';
import {ChemistRemoveRequest} from '@app/screens/module-specific-utilities/components/doctor-approval/view-chemist-remove-request';
import {ViewDoctorRequest} from '@app/screens/module-specific-utilities/components/doctor-approval/view-doctor-request';

import {MarkNotification} from './NotificationModalHelper';
import {NetworkService} from '@app/services';
import CloseIcon from '@mui/icons-material/Close';

function NotificationModal(props: any) {
  const [openModal, setOpenModal] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [showRemoveDoctorModal, setShowRemoveDoctorModal] = useState(false);
  const navigate = useNavigate();
  const [doctorPartyId, setDoctorPartyId] = useState('');
  const [activeNotificationId, setActiveNotificationId] = useState('');
  const [showRemoveChemistModal, setShowRemoveChemistModal] = useState(false);
  const [showDoctorFromUniverseModal, setShowDoctorFromUniverseModal] =
    useState(false);
  const [showDoctorToUniverseModal, setShowDoctorToUniverseModal] =
    useState(false);
  const [doc, setDoc] = useState({});
  const [chemistPartyId, setChemistPartyId] = useState('');
  const dataRedux: any = useSelector(state => state);
  const dispatch = useDispatch();
  const notification = useSelector(filterSelector.getNotificationData());
  const navbarComponentNameSelector: any = useSelector(
    routeSelector.getNavbarComponentName(),
  );

  useEffect(() => {
    const check =
      dataRedux?.appState?.userInfo?.userId &&
      dataRedux?.appState?.userInfo?.staffPositionId &&
      navbarComponentNameSelector;
    if (check) {
      getNotificationList();
    }
  }, [navbarComponentNameSelector]);

  useEffect(() => {
    if (notificationData.length === 0 && notification.length !== 0) {
      setNotificationData(notification);
    }
  }, [notification, notificationData]);

  const getNotificationList = useCallback(() => {
    void (async () => {
      try {
        const accessToken = await getAccessToken();
        if (accessToken) {
          const result = await NetworkService.get(
            `${env.API_HOST}notification?userId=${dataRedux?.appState?.userInfo?.userId}&staffPositionId=${dataRedux?.appState?.userInfo?.staffPositionId}`,
            {},
            {},
          );

          if (result.status !== 200 || result.data.length === 0) {
            return;
          }
          if (result.status == 200) {
            if (result.data.length > 0) {
              const order = result.data.sort(
                (a: {createdOn: string}, b: {createdOn: string}) =>
                  Date.parse(b.createdOn) - Date.parse(a.createdOn),
              );
              setNotificationData(order);
              dispatch(filterStateActions.setNotification(order));
            }
          }
        }
      } catch (err) {
        props.notification ?? setNotificationData(props.notification);
        return err;
      }
    })();
  }, []);

  const handleViewButtonOnClick = (NotifiData: any) => {
    navigate(NOTIFICATION_REDIRECT.cnfReturnApproval);
  };

  const openModel = useCallback(() => {
    setOpenModal(false);
  }, []);

  const openModels = useCallback(() => {
    setOpenModal(!openModal);
    getNotificationList();
  }, []);
  const closeRemoveDoctorModal = () => {
    setShowRemoveDoctorModal(false);
  };

  const closeRemoveChemistModal = () => {
    setShowRemoveChemistModal(false);
  };

  const closeDoctorFromUniverseModal = () => {
    setShowDoctorFromUniverseModal(false);
  };

  const closeDoctorToUniverseModal = () => {
    setShowDoctorToUniverseModal(false);
  };

  const handleViewOnClick = (NotifiData: any) => {
    switch (NotifiData?.data?.actionType) {
      case 'Doctor Remove From Universe':
        setDoctorPartyId(NotifiData.data.partyId);
        setActiveNotificationId(NotifiData?.id);
        setOpenModal(false);
        setShowRemoveDoctorModal(true);
        break;
      case 'Doctor From Universe':
        setDoctorPartyId(NotifiData.data.partyId);
        setOpenModal(false);
        setShowDoctorFromUniverseModal(true);
        setActiveNotificationId(NotifiData?.id);
        setDoc({
          partyId: NotifiData.data.partyId,
          partyName: NotifiData.data.partyName,
          mrStaffPositionId: NotifiData.data.mrStaffPositionId,
          actionType: NotifiData.data.actionType,
        });
        break;
      case 'Doctor To Universe':
        setDoctorPartyId(NotifiData.data.partyId);
        setOpenModal(false);
        setShowDoctorFromUniverseModal(false);
        setShowDoctorToUniverseModal(true);
        setActiveNotificationId(NotifiData?.id);
        setDoc({
          partyId: NotifiData.data.partyId,
          partyName: NotifiData.data.partyName,
          mrStaffPositionId: NotifiData.data.mrStaffPositionId,
          actionType: NotifiData.data.actionType,
        });
        break;
      case 'Chemist Remove From Universe':
        setChemistPartyId(NotifiData.data.partyId);
        setActiveNotificationId(NotifiData?.id);
        setOpenModal(false);
        setShowRemoveChemistModal(true);
        break;
      default:
    }
  };

  const markNotificationAsReadFn = (
    actionType: string,
    notificationId: any,
  ) => {
    if (
      actionType === 'Doctor Remove From Universe' ||
      actionType === 'Chemist Remove From Universe' ||
      actionType === 'Doctor To Universe' ||
      actionType === 'Doctor From Universe'
    )
      return;
    MarkNotification(notificationId);
  };

  return (
    <div>
      <div className="bellend">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          data-testid="open-popup"
          onClick={openModels}
          edge="end"
          data-testId={'notificationsButton'}>
          <NotificationsNoneOutlined color="primary" />
          {notificationData.filter((e: any) => !e?.isRead).length > 0 && (
            <div
              style={{
                position: 'absolute',
                right: 6.7,
                top: 6.7,
                height: 6.7,
                width: 6.7,
                backgroundColor: 'red',
                borderRadius: 50,
              }}>
              .
            </div>
          )}
        </IconButton>
      </div>

      {openModal && (
        <Modal
          isOpen={true}
          style={{
            content: {
              widows: 'auto',
              height: 'auto',
              border: '1px',
              right: 0,
              alignSelf: 'flex-end',
              backgroundColor: 'rgba(0,0,0,0)',
              position: 'static',
              inset: '40px 0px 40px 40px',
            },
            overlay: {zIndex: 2000},
          }}>
          <div className="modalContainer">
            <div className="titleCloseBtn">
              <div className="body">
                <p>Notifications</p>
              </div>
              <button onClick={() => getNotificationList()}>
                <div className="">
                  <Sync className="syncLogo" />
                </div>
              </button>
              <button data-testid="close-popup" onClick={openModel}>
                <div className="body close">
                  <CloseIcon />
                </div>
              </button>
            </div>
            <div className="TodaysDate">
              <p>Today, {moment().format('Do MMMM YYYY')}</p>
            </div>
            <div className="body scrollBarModalBody">
              <List className={notificationData.length > 0 ? 'listClass' : ''}>
                {notificationData.length > 0 ? (
                  Object.entries(
                    notificationData.reduce((group: any, product: any) => {
                      const {createdOn}: any = product;
                      group[createdOn] = group[createdOn] ?? [];
                      group[createdOn].push(product);
                      return group;
                    }, {}),
                  ).map((data: any) => {
                    return (
                      <div key={data}>
                        <p>{moment(data[0]).format('Do MMMM YYYY')}</p>
                        {data[1].map((NotifiData: any, index: number) => {
                          return (
                            <div
                              data-testid={`notifyData`}
                              onClick={() => {
                                markNotificationAsReadFn(
                                  NotifiData?.data?.actionType,
                                  NotifiData.id,
                                );
                              }}
                              onKeyDown={() => {
                                markNotificationAsReadFn(
                                  NotifiData?.data?.actionType,
                                  NotifiData.id,
                                );
                              }}
                              className="modalContainerEachRow"
                              key={NotifiData?.message}
                              role="button">
                              <p className="timeRow">
                                {moment
                                  .utc(NotifiData?.createdOn)
                                  .local()
                                  .format('LT')}
                              </p>
                              {!NotifiData?.isRead && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    right: 6.7,
                                    top: 6.7,
                                    height: 6.7,
                                    width: 6.7,
                                    background: '#E02020',
                                    borderRadius: '50px',
                                  }}
                                />
                              )}
                              <p>{NotifiData?.message}</p>
                              {(NotifiData?.data?.actionType ===
                                'Chemist Remove From Universe' ||
                                NotifiData?.data?.actionType ===
                                  'Doctor From Universe' ||
                                NotifiData?.data?.actionType ===
                                  'Doctor Remove From Universe' ||
                                NotifiData?.data?.actionType ===
                                  'Doctor To Universe') &&
                                !NotifiData?.isRead && (
                                  <Button
                                    sx={{
                                      textTransform: 'none',
                                    }}
                                    size={'small'}
                                    className="viewButtonStyle"
                                    onClick={() => {
                                      handleViewOnClick(NotifiData);
                                    }}
                                    data-testId={`viewButton-${index}`}>
                                    View
                                  </Button>
                                )}
                              {NotifiData?.data?.actionType ===
                                'InventoryTransferToCNF' &&
                                !NotifiData?.isRead && (
                                  <div className="viewButtonParent">
                                    <Button
                                      size={'small'}
                                      className="viewButtonStyle"
                                      onClick={() => {
                                        handleViewButtonOnClick(NotifiData);
                                      }}
                                      data-testId={`viewButton-${index}`}>
                                      View
                                    </Button>
                                  </div>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <p>No Notification</p>
                  </div>
                )}
              </List>
            </div>
          </div>
        </Modal>
      )}
      {showRemoveDoctorModal && (
        <DoctorRemoveRequest
          viewPopupDR={showRemoveDoctorModal}
          onClose={closeRemoveDoctorModal}
          partyId={doctorPartyId}
          notificationId={activeNotificationId}
        />
      )}
      {showRemoveChemistModal && (
        <ChemistRemoveRequest
          viewPopupChemist={showRemoveChemistModal}
          onClose={closeRemoveChemistModal}
          partyId={chemistPartyId}
          notificationId={activeNotificationId}
        />
      )}
      {showDoctorFromUniverseModal && (
        <ViewDoctorRequest
          doc={doc}
          viewPopup={true}
          onClose={closeDoctorFromUniverseModal}
          addDoctorFromUniverse={true}
          notificationId={activeNotificationId}
        />
      )}
      {showDoctorToUniverseModal && (
        <ViewDoctorRequest
          doc={doc}
          viewPopup={true}
          onClose={closeDoctorToUniverseModal}
          addDoctorFromUniverse={false}
          notificationId={activeNotificationId}
        />
      )}
    </div>
  );
}

export default NotificationModal;
