import {useState, useEffect} from 'react';
import {CustomModal} from '@app/components/widgets/CustomModal';
import {Grid, TextField, Box} from '@mui/material';
import {RejectWithReasonModalTP} from '../common-component/RejectWithReasonModalTP';
import {ApproveModalTP} from '../common-component/ApprovalModalTP';
import {useStyles} from './styles';
import CustomTextFieldRowTP from '../common-component/CustomTextFieldRowTP';
import {CustomTextFieldTP} from '../common-component/CustomTextFieldTP';
import CustomTitle from '../common-component/CustomTitle';
import {FooterButtonTP} from '../common-component/footerButtonTP';
import {useSelector, useDispatch} from 'react-redux';
import {
  doctorRequestsCreator,
  approveDoctorRequestCreator,
  rejectDoctorRequestCreator,
  doctorApprovalSelector,
  doctorApprovalActions,
} from '@app/screens/module-specific-utilities/pages/doctor-approval/redux';
import {MarkNotification} from '@app/components/right-sidebar/NotificationModalHelper';
import {CircularProgress} from '@material-ui/core';
import {approveRequestCreator} from '@app/screens/module-specific-utilities/pages/doctor-approval/redux/slice';

const DoctorRemoveInfo = (props: any) => {
  const classes = useStyles();
  const loader = useSelector(doctorApprovalSelector.getDoctorRemoveLoading());
  return (
    <Grid container style={{flexDirection: 'row'}}>
      {loader && (
        <Box
          sx={{display: 'flex', justifyContent: 'center', mt: 10}}
          className={classes.loader}>
          <CircularProgress />
        </Box>
      )}
      <CustomTextFieldRowTP
        righTlable={'Name'}
        rightValue={props?.data?.name}
        leftLable={'Number of Patient/Day (A)'}
        leftValue={props?.data?.numberOfPatientsDay}
      />
      <CustomTextFieldRowTP
        righTlable={'Address'}
        rightValue={props?.data?.address}
        leftLable={'Average Rx Value in ₹ (B)'}
        leftValue={props?.data?.averageRxValue}
      />
      <CustomTextFieldRowTP
        righTlable={'City'}
        rightValue={props?.data?.cityName}
        leftLable={'OP Days (C)'}
        leftValue={props?.data?.opdays}
      />
      <CustomTextFieldRowTP
        righTlable={'Qualification'}
        rightValue={props?.data?.qualificationName}
        leftLable={'Industry Potential'}
        leftValue={props?.data?.industryPotential}
      />
      <CustomTextFieldRowTP
        righTlable={'Speciality by Qualification'}
        rightValue={props?.data?.specialisationName}
        leftLable={'My Division Potential'}
        leftValue={props?.data?.myDivisionPotential}
      />
      <Grid
        container
        className={classes.TextFieldRowContainer}
        style={{flexDirection: 'row-reverse'}}>
        <CustomTextFieldTP
          label={'Is KYC/CORE'}
          value={props?.data?.isKYC ? 'Yes' : 'No'}
        />
      </Grid>
      <Grid container style={{marginTop: 20}}>
        <CustomTitle title={"Last 3 Month's Support"} variant={'h6'} />
      </Grid>
      <Grid container style={{justifyContent: 'space-around'}}>
        <CustomTextFieldTP
          label={props?.data?.monthlyName1}
          value={props?.data?.monthlySupport1 ?? 0}
          customStyle={{width: 297.3, flexWrap: 'wrap'}}
        />
        <CustomTextFieldTP
          label={props?.data?.monthlyName2}
          value={props?.data?.monthlySupport2 ?? 0}
          customStyle={{width: 297.3, flexWrap: 'wrap'}}
        />
        <CustomTextFieldTP
          label={props?.data?.monthlyName3}
          value={props?.data?.monthlySupport3 ?? 0}
          customStyle={{width: 297.3, flexWrap: 'wrap'}}
        />
      </Grid>
      <Grid container style={{marginTop: 20}}>
        <CustomTitle title={'Reason for Removing Doctor'} variant={'h6'} />
      </Grid>
      <Grid
        container
        style={{
          justifyContent: 'flex-start',
          marginBottom: 20,
          flex: 1,
          marginLeft: 45,
        }}>
        <TextField
          defaultValue={'Doctor Expired'}
          InputProps={{
            readOnly: true,
            className: classes.root2,
          }}
        />
      </Grid>
    </Grid>
  );
};
export const DoctorRemoveRequest = (props: any) => {
  const [viewRejectModal, setViewRejectModal] = useState<boolean>(false);
  const [approvePopup, setApprovePopup] = useState<boolean>(false);
  const dispatch = useDispatch();

  const doctorRemoveData = useSelector(
    doctorApprovalSelector.getDoctorRequests(),
  );

  const doctorRemovalCompleted = useSelector(
    doctorApprovalSelector.getDoctorRemoveDone(),
  );

  const closeDocPopup = () => {
    props.onClose();
    dispatch(approveRequestCreator({universetype: 'doctor'}));
  };
  const closeRejectModal = () => {
    setViewRejectModal(false);
    setApprovePopup(false);
  };
  const body = {
    partyId: doctorRemoveData.partyId,
    partyName: doctorRemoveData.name,
    reason: '',
  };

  const handleDoctorRemovalApprove = () => {
    dispatch(approveDoctorRequestCreator(body));
  };

  const handleDoctorRemovalRejection = (reason: string) => {
    const updatedBody = {...body, reason: reason};
    dispatch(rejectDoctorRequestCreator(updatedBody));
    if (props?.notificationId) {
      MarkNotification(props?.notificationId);
    }
  };
  useEffect(() => {
    dispatch(doctorRequestsCreator({partyId: props?.partyId}));
  }, [props?.partyId]);

  useEffect(() => {
    if (doctorRemovalCompleted) {
      if (props?.notificationId) {
        MarkNotification(props?.notificationId);
      }
    }
  }, [doctorRemovalCompleted, props?.notificationId]);

  useEffect(
    function closeModal() {
      if (doctorRemovalCompleted) {
        closeDocPopup();
        dispatch(doctorApprovalActions.resetDoctorRemoveDone(''));
      }
    },
    [doctorRemovalCompleted],
  );
  return (
    <div>
      <CustomModal
        fullWidth={true}
        open={props.viewPopupDR}
        onClose={closeDocPopup}
        header={
          <CustomTitle title={'Basic Customer Information'} variant={'h6'} />
        }
        children={<DoctorRemoveInfo data={doctorRemoveData} />}
        footer={
          <FooterButtonTP
            firstLabel={'Approve'}
            secondLabel={'Reject with Reason'}
            firstButtonStyle={{textTransform: 'none'}}
            secondButtonStyle={{textTransform: 'none'}}
            secondOnClick={() => setViewRejectModal(true)}
            firstOnClick={() => setApprovePopup(true)}
          />
        }
      />
      {viewRejectModal && (
        <RejectWithReasonModalTP
          viewRejectModal={viewRejectModal}
          closeModal={() => {
            closeRejectModal();
          }}
          reasonText={handleDoctorRemovalRejection}
        />
      )}

      {approvePopup && (
        <ApproveModalTP
          viewApproveModal={approvePopup}
          closeModal={() => {
            setApprovePopup(false);
          }}
          onApprove={handleDoctorRemovalApprove}
        />
      )}
    </div>
  );
};
